<template>
  <gw-dialog v-model="dialog" @open:dialog="onOpen" @close:dialog="onClose">
    <v-card v-if="dialog">
      <v-card-text v-if="sXml" class="pa-0">
        <vue-code-highlight language="xml">
          <pre v-text="sXml" />
        </vue-code-highlight>
      </v-card-text>
    </v-card>

    <template #prepend-actions>
      <invoice-xml-download-btn :uuid="sUUID" :xml="sXml" />
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import InvoiceXmlDownloadBtn from "@/modules/invoices/components/InvoiceXmlDownloadBtn.vue";
import { InvoiceData, MailLog } from "@planetadeleste/vue-mc-gw";
import { component as VueCodeHighlight } from "vue-code-highlight";
import { get } from "lodash";

@Component({
  components: { InvoiceXmlDownloadBtn, GwDialog, VueCodeHighlight },
})
export default class MailLogXmlDialog extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop(Object) readonly item!: InvoiceData;

  sXml = "";

  get mailLogId(): number | null {
    return get(this.item, "maillog_id", null) as number | null;
  }

  get sUUID(): string | undefined {
    return get(this.item, "uuid");
  }

  async onOpen() {
    if (!this.mailLogId) {
      return;
    }

    const obMailLog = new MailLog({ id: this.mailLogId });
    const obResponse = await obMailLog.getXml();
    this.sXml = obResponse.getData().data;
  }

  onClose() {
    this.sXml = "";
  }
}
</script>
